import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "./../images/logo.svg" // Tell Webpack this JS file uses this image
import Menu from "./menu"
import Close from "./close"

const Header = ({ siteTitle }) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  useEffect(() => {
    if (isNavOpen) {
      document.body.classList.add("nav-open")
    } else {
      document.body.classList.remove("nav-open")
    }
  }, [isNavOpen])

  return (
    <header>
      <h1>
        <Link to="/">
          <img src={logo} className="logo" alt={siteTitle} />
        </Link>
      </h1>
      <button className="toogle-nav" onClick={() => setIsNavOpen(!isNavOpen)}>
        {isNavOpen ? <Close /> : <Menu />}
      </button>
      <nav>
        <ul className="main-nav">
          <li>
            <Link
              className="nav-item"
              to="/#unser-angebot"
              onClick={() => setIsNavOpen(false)}
            >
              Unser Angebot
            </Link>
          </li>
          <li>
            <Link
              className="nav-item"
              to="/#oeffnungszeiten"
              onClick={() => setIsNavOpen(false)}
            >
              Öffnungszeiten
            </Link>
          </li>
          <li>
            <Link
              className="nav-item"
              to="/#anfahrt-und-kontakt"
              onClick={() => setIsNavOpen(false)}
            >
              Anfahrt &amp; Kontakt
            </Link>
          </li>
          <li className="nav-social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/krempundblumen/"
                  className="nav-social__link icon-facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Kremp und Blumen bei Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/krempundblumen/"
                  className="nav-social__link icon-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Kremp und Blumen bei Instagram</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
