import React from "react"

export default function Menu() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 90 1040 768"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g>
        <path
          d="M649 238h52h38l36 -1h33l36 -1l22 -1l19 -1l17 -1h9l9 -1l7 -1l6 -1h1.5h0.5q4 -1 7.5 -2t6.5 -3q6 -3 10 -8t6 -14q2 -10 -2 -18t-11 -12q0 -1 -0.5 -1h-0.5l-3 -1l-3 -1l-4 -1l-4 -1l-5 -1l-9 -2l-7 -1l-11 -1l-18 -2l-15 -1l-23 -2l-34 -2l-22 -1l-13 -1l-3 -54l-99 5 l-114 7l-79 4l-62 4l-64 5l-48 4l-29 2l-27 3l-21 2l-103 -3v21h-1l-3 2l-3 1q-8 6 -12.5 15t-0.5 22q2 8 7 13t12 8q1 1 3 1.5t4 1.5l5 1h0.5h1.5l6 1l6 2l11 1l8 1l14 2l10 1l16 1l17 1l25 2l35 1l38 2l33 1l35 1l56 1l81 1h59h55v0zM839 556h10h8q8 0 14 -0.5t10 -1.5 t7.5 -2t7.5 -3q12 -8 14.5 -22t-7.5 -26q-4 -4 -9.5 -7.5t-14.5 -5.5l-6 -2l-11 -2l-13 -3l-6 -1q-29 -5 -57 -9.5t-57 -8.5l-6 -1v-1q-1 -7 -4.5 -12.5t-8.5 -8.5l-3 -1q-3 -3 -7 -4t-9 -2l-3 -1l-6 -1l-12 -1q-1 -1 -2 -1h-2l-15 -2l-22 -2l-36 -3l-40 -3l-41 -3l-43 -3 l-28 -2l-29 -2l-41 -2l-40 -2l-32 -2h-31l-14 -1h-19h-12h-17h-9l-9 1h-8l-7 1q-5 1 -9 1.5t-8 1.5l-4 1l-2 1l-11 1h-13h-8v1l-20 2l-27 2l-14 2l-7 1l-7 1h-2h-1l-6 1q-1 1 -1.5 1h-1.5l-4 1q-6 2 -10 4.5t-7 5.5l-2 2q-6 7 -7.5 16.5t3.5 18.5q2 6 6 9t8 5q3 2 7 3l8 2 q3 1 5.5 1.5t5.5 0.5l9 1l11 2l10 1h12l19 2l21 1l31 1l44 2l38 1l30 1l7 1q11 2 23 4l24 4l26 4l26 4l27 3q4 1 7.5 1.5t6.5 0.5l29 4l29 3l30 3l30 3l30 3q4 1 8 1t8 1l31 2q4 1 7.5 1t7.5 1l32 2l39 3l34 2l16 1l23 1l21 1l20 1h18h10v0zM994 796v0q3 0 6 -0.5t6 -2.5 l2 -1h1v0l2 -1l2 -2l5 -4l3 -5q2 -5 2.5 -8t-0.5 -9l-1 -6l-5 -7l-4 -4l-0.5 -0.5l-0.5 -0.5l-1 -2h-0.5h-0.5l-1 -1h-2l-1 -1q-3 -1 -5 -1.5t-4 -1.5h-6l-8 -2l-46 -4l-599 -59l-4 34l-126 -6l-3 57l789 38v0z"
          style={{ fill: "#404040" }}
        />
      </g>
    </svg>
  )
}
