import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <p className="footer-copyright">
      &copy; Kremp und Blumen {new Date().getFullYear()}
    </p>
    <Link to="/imprint/">Impressum</Link>
    <ul className="nav-social">
      <li>
        <a
          href="https://www.facebook.com/krempundblumen/"
          className="nav-social__link icon-facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Kremp und Blumen bei Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/krempundblumen/"
          className="nav-social__link icon-instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Kremp und Blumen bei Instagram</span>
        </a>
      </li>
    </ul>
  </footer>
)

export default Footer
