import React from "react"

export default function Close() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 90 1040 768"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
    >
      <g>
        <path
          d="M920 960l78 -74l-12 -13l-19 -23l-63 -77l-15 -18l-21 -25l-13 -15l-13 -13l-11 -13l-12 -11l-12 -12l-12 -11l-12 -10l-48 -41l-98 -81l-24 -20l310 -283l-34 -37l46 -49l5 -6l57 -82l2 -3q2 -2 4 -4.5t4 -4.5l3 -4l3 -4q4 -6 6 -11t4 -12q3 -17 -3.5 -34t-25.5 -27
        q-11 -5 -20.5 -5.5t-19.5 1.5q-4 1 -7.5 2t-7.5 3q-5 2 -10.5 5t-12.5 7l-12 8l-15 9l-16 10l-17 12l-19 13l-29 22l-21 15l-24 19l-24 18l-23 18l-21 17l-20 17l-19 16l-17 15q-10 9 -18.5 17.5l-15.5 15.5q-32 33 -63.5 68t-63.5 73l-19 23l-383 -373l-76 76l63 63l63 62
        l-4 4l-2 2l-2 2l-4 6q-11 17 -10 31t8 29q1 4 3.5 7.5t5.5 8.5l6 9q1 1 2 2.5t2 2.5l8 11l10 11l10 13l12 14l20 22l15 16l16 17l26 27l38 39l42 43l9 8l-45 60l-88 116l-50 65l-9 11l-27 35l1 1l-4 3l-29 37l78 73l302 -276l20 19l53 50l33 30l52 47l29 26l36 32l33 28
        l23 19l13 11l13 10l12 9l11 8l10 7l9 6l4 2l4 2q8 5 16 8t18 4q11 1 21 -2.5t17 -8.5l5 -4l1 -2l21 20v0z"
          style={{ fill: "#404040" }}
        />
      </g>
    </svg>
  )
}
